<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="items"
      :items-per-page="-1"
      :loading="loading"
      hide-default-footer
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-spacer></v-spacer>
          <v-dialog
            v-model="dialog"
            scrollable
            persistent
            :retain-focus="false"
            max-width="1000px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="accent"
                dark
                class="mb-2"
                v-bind="attrs"
                v-on="on"
                @click="isCreating = true"
              >
                Создать
              </v-btn>
            </template>
            <v-card class="pa-1">
              <v-card-title class="mb-5 align-start">
                <span class="text-h5 mr-2">{{ formTitle }}</span>
                <div class="text-h5 accent--text">
                  {{ itemCurrentName }}
                </div>
              </v-card-title>

              <v-card-text>
                <v-row class="pt-4">
                  <v-col cols="12" class="py-0">
                    <v-text-field
                      ref="editedItem.url"
                      v-model="editedItem.url"
                      :rules="[rules.required, rules.firstSlash]"
                      :error="errors.url ? true : false"
                      :error-messages="errors.url"
                      hint="должен начинаться с / "
                      label="Url"
                      @focus="errors.url = null"
                      outlined
                      dense
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" class="py-0">
                    <v-tabs v-model="tab">
                      <v-tab v-for="lang in languagesList" :key="lang.iso">
                        <div class="d-flex align-center">
                          <country-flag
                            :country="lang.icon"
                            class="ma-0 elevation-3"
                          />
                          {{ lang.name }}
                        </div>
                      </v-tab>
                      <v-tab-item
                        v-for="lang in languagesList"
                        :key="lang.iso + '_tab_tags'"
                        eager
                        class="pt-3"
                      >
                        <v-text-field
                          :ref="`editedItem.title.${lang.iso}`"
                          v-model="editedItem.title[lang.iso]"
                          label="Title"
                          outlined
                          dense
                          :rules="[rules.required]"
                          :error="errors[`title.${lang.iso}`] ? true : false"
                          :error-messages="errors[`title.${lang.iso}`]"
                          @focus="errors[`title.${lang.iso}`] = null"
                        ></v-text-field>
                        <v-textarea
                          :ref="`editedItem.description.${lang.iso}`"
                          v-model="editedItem.description[lang.iso]"
                          label="Description"
                          outlined
                          dense
                          :rules="[rules.required]"
                          :error="
                            errors[`description.${lang.iso}`] ? true : false
                          "
                          :error-messages="errors[`description.${lang.iso}`]"
                          @focus="errors[`description.${lang.iso}`] = null"
                        ></v-textarea>
                        <v-text-field
                          :ref="`editedItem.page_title.${lang.iso}`"
                          v-model="editedItem.page_title[lang.iso]"
                          label="H1"
                          outlined
                          dense
                          :error="
                            errors[`page_title.${lang.iso}`] ? true : false
                          "
                          :error-messages="errors[`page_title.${lang.iso}`]"
                          @focus="errors[`page_title.${lang.iso}`] = null"
                        ></v-text-field>
                        <b>SEO текст</b>
                        <editor
                          :value="editedItem.content_description[lang.iso]"
                          @input="
                            (v) =>
                              (editedItem.content_description[lang.iso] = v)
                          "
                        />
                        <v-row class="py-5" dense align="center">
                          <v-col cols="auto"
                            ><b class="mt-20">FAQ блок</b></v-col
                          >
                          <v-col cols="auto">
                            <v-btn
                              @click="
                                addFaqItem({
                                  obj: editedItem.faq,
                                  lang: lang.iso,
                                })
                              "
                              >Добавить</v-btn
                            >
                          </v-col>
                          <v-col cols="12" class="pt-2">
                            <v-text-field
                              v-model="editedItem.faq[lang.iso].title"
                              label="Заголовок блока(h2)"
                              :rules="[rules.max(100)]"
                              counter="100"
                              outlined
                              dense
                            ></v-text-field>
                          </v-col>
                          <v-col
                            v-for="(item, ind) in faqItems(lang.iso)"
                            :key="`faq_${lang.iso}_${ind}`"
                            cols="12"
                          >
                            <b class="mb-5 me-3">{{ `Вопрос #${ind + 1}` }}</b>
                            <v-icon
                              small
                              @click="
                                deleteFaqItem({
                                  obj: editedItem.faq,
                                  lang: lang.iso,
                                  index: ind,
                                })
                              "
                            >
                              mdi-delete
                            </v-icon>
                            <v-text-field
                              v-model="item.question"
                              :label="`Вопрос #${ind + 1}`"
                              class="mt-3"
                              :rules="[rules.max(250)]"
                              counter="250"
                              outlined
                              dense
                            ></v-text-field>
                            <v-textarea
                              v-model="item.answer"
                              :label="`Ответ #${ind + 1}`"
                              :rules="[rules.max(5000)]"
                              counter="5000"
                              hint="текст или HTML разметка"
                              outlined
                              dense
                            ></v-textarea>
                          </v-col>
                        </v-row>
                        <v-row
                          v-if="editedItem.url.includes('courses')"
                          class="py-5"
                          dense
                          align="center"
                        >
                          <v-col cols="auto"
                            ><b class="mt-20">Suitable For блок</b></v-col
                          >
                          <v-col cols="auto">
                            <v-btn
                              @click="
                                addSuitableForItem({
                                  obj: editedItem.suitable_for,
                                  lang: lang.iso,
                                })
                              "
                              >Добавить</v-btn
                            >
                          </v-col>
                          <v-col cols="12" class="pt-2">
                            <v-text-field
                              v-model="editedItem.suitable_for[lang.iso].title"
                              label="Заголовок блока(h2)"
                              :rules="[rules.max(100)]"
                              counter="100"
                              outlined
                              dense
                            ></v-text-field>
                          </v-col>
                          <v-col
                            v-for="(item, ind) in suitableForItems(lang.iso)"
                            :key="`suitable_for_${lang.iso}_${ind}`"
                            cols="12"
                          >
                            <b class="mb-5 me-3">{{ `Таб #${ind + 1}` }}</b>
                            <v-icon
                              small
                              @click="
                                deleteSuitableForItem({
                                  obj: editedItem.suitable_for,
                                  lang: lang.iso,
                                  index: ind,
                                })
                              "
                            >
                              mdi-delete
                            </v-icon>
                            <v-text-field
                              v-model="item.title"
                              :label="`Заголовок #${ind + 1}`"
                              class="mt-3"
                              :rules="[rules.max(250)]"
                              counter="250"
                              outlined
                              dense
                            ></v-text-field>
                            <v-textarea
                              v-model="item.description"
                              :label="`Описание #${ind + 1}`"
                              :rules="[rules.max(5000)]"
                              counter="5000"
                              hint="текст или HTML разметка"
                              outlined
                              dense
                            ></v-textarea>
                            <v-btn
                              v-if="!item.image"
                              block
                              color="secondary"
                              @click="triggerFileSelection"
                            >
                              загрузить изображение
                            </v-btn>
                            <input
                              type="file"
                              style="display: none"
                              @change="updateMainImage($event, ind, lang.iso)"
                            />
                            <v-img
                              v-if="item.image"
                              style="height: 100%"
                              max-height="400"
                              :src="item.image"
                            ></v-img>
                            <v-btn
                              v-if="item.image"
                              block
                              color="secondary"
                              @click="deleteImage(item)"
                              >Удалить картинку</v-btn
                            >
                          </v-col>
                        </v-row>
                      </v-tab-item>
                    </v-tabs>
                  </v-col>
                </v-row>
              </v-card-text>

              <v-card-actions class="pb-4">
                <v-spacer></v-spacer>
                <v-btn color="accent" outlined @click="close"> Отмена </v-btn>
                <v-btn color="accent" @click="save">
                  <template v-if="editedIndex > -1"> Изменить </template>
                  <template v-else> Создать </template>
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog v-model="dialogDelete" max-width="500px">
            <template>
              <v-card class="pa-1">
                <v-card-title
                  class="text-h5 text-center justify-center flex-column mb-5"
                  >Удалить Url?<br />
                  <span class="accent--text">{{
                    itemCurrentName
                  }}</span></v-card-title
                >

                <v-card-actions class="pb-4">
                  <v-spacer></v-spacer>
                  <v-btn color="accent" outlined @click="closeDelete"
                    >Отмена</v-btn
                  >
                  <v-btn color="accent" @click="deleteItemConfirm">OK</v-btn>
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
            </template>
          </v-dialog>
        </v-toolbar>
      </template>
      <template v-slot:item.created_at="{ item }">
        {{ formatData(item.created_at) }}
      </template>
      <template v-slot:item.updated_at="{ item }">
        {{ formatData(item.update_at) }}
      </template>
      <template v-slot:item.actions="{ item }">
        <v-icon small class="mr-2" @click="editItem(item)"> mdi-pencil </v-icon>
        <v-icon small @click="deleteItem(item)"> mdi-delete </v-icon>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import CountryFlag from "vue-country-flag";
import api from "../../../../api";
import { mapGetters } from "vuex";
import rules from "@/mixins/rules";
import slug from "@/mixins/slug";
import faqSeo from "@/mixins/faq-seo";
import suitableForSeo from "@/mixins/suitable_for-seo";
import moment from "moment";
import Editor from "@/components/Editor.vue";
import copyValueObjs from "@/helpers/copyValueObjs";

export default {
  name: "SeoLinks",
  mixins: [rules, slug, faqSeo, suitableForSeo],
  components: { CountryFlag, Editor },
  data() {
    return {
      dialog: false,
      dialogDelete: false,
      isCreating: false,
      headers: [
        {
          text: "Url",
          align: "start",
          sortable: true,
          value: "url",
        },
        {
          text: "Создан",
          value: "created_at",
          align: "center",
        },
        {
          text: "Обновлен",
          value: "updated_at",
          align: "center",
        },
        {
          text: "Действия",
          value: "actions",
          sortable: false,
          align: "end",
        },
      ],
      items: [],
      editedIndex: -1,
      editedItem: null,
      loading: true,
      errors: {},
      tab: 0,
      editorConfig: {
        allowedContent: true,
      },
    };
  },

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "Новый Url" : "Изменить Url";
    },
    itemCurrentName() {
      return this.editedIndex > -1 ? this.items[this.editedIndex].url : "";
    },
    ...mapGetters(["languagesList"]),
    faqItems: (vm) => {
      return (lang) => {
        return vm.editedItem.faq[lang].items;
      };
    },
    suitableForItems: (vm) => {
      return (lang) => {
        return vm.editedItem.suitable_for[lang].items;
      };
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
  },
  created() {
    this.getItems();
    this.editedItem = { ...this.createItem() };
  },
  methods: {
    deleteImage(item) {
      item.image = null;
    },
    async editItem(item) {
      this.editedIndex = this.items.indexOf(item);
      const loadItem = await this.getItem(item.id);
      this.languagesList.forEach((lang) => {
        if (!loadItem.faq[lang.iso]) {
          loadItem.faq[lang.iso] = { title: "", items: [] };
        }
        if (
          !loadItem?.suitable_for?.[lang.iso] &&
          loadItem.url.includes("courses")
        ) {
          if (!loadItem.suitable_for) loadItem.suitable_for = {};
          loadItem.suitable_for[lang.iso] = { title: "", items: [] };
        }
      });

      if (!loadItem.url.includes("courses")) {
        delete loadItem?.suitable_for;
      }

      this.editedItem = copyValueObjs(this.createItem(loadItem), loadItem);
      this.dialog = true;
      this.resetErrors(this.editedItem, "editedItem");
    },

    triggerFileSelection(e) {
      e.currentTarget.nextSibling.click();
    },

    updateMainImage(e, index, locale) {
      const file = e.target.files[0];
      if (!file) return;

      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);

      // Use an arrow function here to keep the Vue instance's `this` context
      fileReader.addEventListener("load", () => {
        // Access the correct index and locale within the Vue instance
        const suitableItems = this.suitableForItems(locale);
        if (suitableItems && suitableItems[index]) {
          suitableItems[index].image = fileReader.result;
        }
      });
    },

    deleteItem(item) {
      this.editedIndex = this.items.indexOf(item);
      this.dialogDelete = true;
    },

    async deleteItemConfirm() {
      await this.removeItem(this.items[this.editedIndex].id);
      this.getItems();
      this.closeDelete();
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = { ...this.createItem() };
        this.editedIndex = -1;
        this.errors = {};
        this.tab = 0;
        this.resetErrors(this.editedItem, "editedItem");
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = { ...this.createItem() };
        this.editedIndex = -1;
      });
    },

    async save() {
      let formHasErrors = this.validationObject(this.editedItem, "editedItem");
      if (this.editedItem.url.includes("courses")) {
        const noSuitableFor = this.editedItem.suitable_for
          ? Object.keys(this.editedItem.suitable_for).some(
              (lang) =>
                !this.editedItem.suitable_for[lang].items.length ||
                !this.editedItem.suitable_for[lang].title ||
                this.editedItem.suitable_for[lang].items.some(
                  (item) => !item.title || !item.description || !item.image
                )
            )
          : null;
        const tooBigDescForTab = this.editedItem.suitable_for
          ? Object.keys(this.editedItem.suitable_for).some((lang) =>
              this.editedItem.suitable_for[lang].items.some(
                (item) => item.description && item.description.length > 500
              )
            )
          : null;
        if (this.editedIndex === -1) {
          delete this.editItem.suitable_for;
        } else if (this.editedIndex > -1) {
          if (noSuitableFor) {
            this.$store.dispatch("showSnackbar", {
              msg: "Просмотрите ли вы точно заполнили все поля на всех языках для блока 'suitable_for'",
              color: "error",
              show: true,
            });
            return;
          } else if (tooBigDescForTab) {
            this.$store.dispatch("showSnackbar", {
              msg: "Для описания для блока в 'suitable_for' допустимо максимально 500 символов",
              color: "error",
              show: true,
            });
            return;
          }
        }
      }
      if (formHasErrors) {
        this.$store.dispatch("showSnackbar", {
          msg: "Заполните обязательные поля",
          color: "error",
          show: true,
        });
        return;
      }

      await this.saveItem();
    },

    async saveItem() {
      if (
        this.editedItem.url.includes("courses") &&
        this.editedItem.suitable_for
      ) {
        const checkImages = Object.keys(this.editedItem.suitable_for).map(
          (lang) => {
            return this.editedItem.suitable_for[lang].items.forEach((item) => {
              if (
                item.image &&
                (item.image.startsWith("http") ||
                  item.image.startsWith("data:"))
              ) {
                return true;
              }
            });
          }
        );
        if (checkImages) await this.updateImage();
      }

      if (this.editedIndex > -1) {
        await this.updateItem();
      } else {
        await this.addItem();
      }
      this.getItems();
    },

    async updateImage() {
      await Promise.all(
        Object.keys(this.editedItem.suitable_for).map(async (lang) => {
          await Promise.all(
            this.editedItem.suitable_for[lang].items.map(async (item) => {
              if (item.image && item.image.startsWith("http")) {
                try {
                  // Convert image URL to Base64
                  item.image = await this.convertImageUrlToBase64(item.image);
                } catch (error) {
                  console.error(
                    "Failed to convert image URL to Base64:",
                    error
                  );
                }
              }
            })
          );
        })
      );
    },

    async convertImageUrlToBase64(url) {
      return new Promise((resolve, reject) => {
        fetch(url)
          .then((response) => response.blob())
          .then((blob) => {
            const reader = new FileReader();
            reader.onloadend = () => {
              resolve(reader.result); // This will be the Base64 string
            };
            reader.onerror = (error) => reject(error);
            reader.readAsDataURL(blob);
          })
          .catch((error) => reject(error));
      });
    },

    async getItems() {
      this.loading = true;
      try {
        const req = await api.seo.list();
        if (req.data) {
          this.items = req.data;
        }
        this.loading = false;
      } catch (e) {
        console.error(e);
        this.loading = false;
      }
    },

    async addItem() {
      const data = this.editedItem;
      try {
        await api.seo.create(data);
        this.$store.dispatch("showSnackbar", {
          msg: `"${this.editedItem.url}" создан`,
          color: "success",
          show: true,
        });
        this.close();
      } catch (e) {
        console.error(e);
        if (e?.response?.data?.errors) {
          this.errors = { ...e.response.data.errors };
        } else {
          this.$store.dispatch("showSnackbar", {
            msg: "Ошибка создания",
            color: "error",
            show: true,
          });
        }
      }
    },

    async updateItem() {
      const data = this.editedItem;
      try {
        await api.seo.update(this.editedItem.id, data);
        this.$store.dispatch("showSnackbar", {
          msg: `"${this.editedItem.url}" изменен`,
          color: "success",
          show: true,
        });
        this.close();
      } catch (e) {
        console.error("eeeeeeeeeeeeeeee");
        console.error(e);
        if (e?.response?.data?.errors) {
          this.errors = { ...e.response.data.errors };
        } else {
          this.$store.dispatch("showSnackbar", {
            msg: "Ошибка редактирования",
            color: "error",
            show: true,
          });
        }
      }
    },

    async getItem(id) {
      try {
        const req = await api.seo.item(id);
        return req.data;
      } catch (e) {
        console.error(e);
        return false;
      }
    },

    async removeItem(id) {
      try {
        const nameDelete = this.items[this.editedIndex].url;
        await api.seo.delete(id);
        this.$store.dispatch("showSnackbar", {
          msg: `"${nameDelete}" удален`,
          color: "success",
          show: true,
        });
      } catch (e) {
        console.error(e);
        this.$store.dispatch("showSnackbar", {
          msg: "Ошибка удаления",
          color: "error",
          show: true,
        });
      }
    },

    createItem(item = null) {
      let keys = [
        "title",
        "page_title",
        "description",
        "content_description",
        "faq",
        "suitable_for",
      ];

      if (!item || !item.url || !item.url.includes("courses"))
        keys = keys.filter((key) => key !== "suitable_for");

      let localeKeys = {};
      keys.forEach((key) => {
        let obj = {};
        this.languagesList.forEach((lang) => {
          if (key === "faq" || key === "suitable_for") {
            obj[lang.iso] = {
              title: "",
              items: [],
            };
          } else {
            obj[lang.iso] = "";
          }
        });
        localeKeys[key] = obj;
      });
      return {
        ...localeKeys,
        url: "",
      };
    },

    formatData(data) {
      return moment(data).format("DD.MM.YYYY");
    },
  },
};
</script>
