export default {
  methods: {
    addSuitableForItem({ obj, lang }) {
      const item = obj[lang];
      let array = [];
      if (["string", "null"].includes(typeof item))
        this[obj]["suitable_for"][lang] = {};
      if (Array.isArray(obj[lang].items)) array = obj[lang].items;
      array.push({
        index: array.length + 1,
        title: "",
        description: "",
        image: "",
      });
      obj[lang].items = array;
    },
    deleteSuitableForItem({ obj, lang, index }) {
      try {
        obj[lang].items.splice(index, 1);
      } catch (e) {
        console.error(e);
      }
    },
  },
};
